import { Grid, Paper, Typography, Box, useTheme } from "@material-ui/core";
import Weather from "../weather";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AnalysisNotam from "../AnalysisNotam";
import axios from "axios";
import AircraftThumbnail from "../AircraftThumbnail";
import GroundSpeed from "../../charts/GroundSpeed";
import DirectionalCompliance from "../../charts/DirectionalCompliance";
import DecelerationVsDistance from "../../charts/DecelerationVsDistance";
import MapPlot from "./MapPlots";
import { DashboardAirportImage } from "./DashboardAirportImage";
import { Link } from "react-router-dom";

const WEBAPI = process.env.REACT_APP_WEB_API;
const slope_ht = 50;  // height in feet 
const slope_distance = Math.floor(slope_ht / Math.tan(3 * Math.PI/180))

const styles =(theme) => ({
    infobar: {
        fontSize: '10px'
    }
});

export default function LatestLanding({recentLanding, runway, runways, airport}) {
    const classes = useTheme(styles);
    // State
    const [sourceWeatherData, setSourceWeatherData] = useState("")
    const [runwayName, setRunwayName] = useState(runways[runway]?.runwayname);
    const [firstLanding, setFirstLanding] = useState(recentLanding[runway]?.length ? recentLanding[runway][0] : {});
    const [runwayPolygon, setRunwayPolygon] = useState(runways[runway]?.polygon?.coordinates[0])
    const [midpoint1, setMidpoint1] = useState(runways[runway]?.midpoint1?.coordinates)
    const [midpoint2, setMidpoint2] = useState(runways[runway]?.midpoint2?.coordinates)

    const [make, setMake] = useState('')
    const [model, setModel] = useState('')
    const [registration, setRegistration] = useState('')
    const [owner, setOwner] = useState('')
    const [imageUrl, setImageUrl] = useState('')

    const paperRef = useRef(null);

    // Redux
    const airportCode = useSelector(state => state.airportCode.value);

    useEffect(() => {
        setRunwayName(runways[runway]['runwayname']);
        setFirstLanding(recentLanding[runway]?.length ? Object.assign({}, recentLanding[runway][0]) : {});
        setRunwayPolygon(runways[runway]?.polygon?.coordinates[0])
        setMidpoint1(runways[runway]?.midpoint1?.coordinates)
        setMidpoint2(runways[runway]?.midpoint2?.coordinates)
    }, [runway, runways, recentLanding]);

    useEffect(() => {
        if ( !firstLanding.id ) {
            return;
        }
        axios.get(`${WEBAPI}/aircraft/${firstLanding.icaoaddress}`)
        .then((res) => {
          console.log(res.data)
          setMake(res.data.make)
          setModel(res.data.model)
          setRegistration(res.data.registration)
          setOwner(res.data.owner)
  
          setImageUrl(res.data.image_url)
        })
        .catch((err) => {
          console.log(err)
        })
    }, [firstLanding]);

    if ( !('id' in firstLanding) ) {
        return 'No recent landings on this runway.'
    }

    return <div>
        <Typography variant="h6">Last Landing on Runway {runwayName}</Typography><br />

        <Paper ref={paperRef}>
            {/* <Grid container spacing={3}>
                <Grid item xs={12} sm={4} >
                    <Box className={classes.infobar} style={{paddingTop: '10px', paddingLeft: '15px', zoom: 0.9}}>
                    

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <AircraftThumbnail thumbnailURL={imageUrl} style={{width: '100%'}} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {
                            registration ?
                            <Box>
                                <Typography color="textSecondary" display='inline'>
                                Registration:&nbsp;
                                </Typography>
                                <Typography color="textSecondary" variant="subtitle2" display='inline'>
                                {registration}
                                </Typography>
                            </Box>
                            :
                            null
                            }

                            <Box>
                            <Typography color="textSecondary" display='inline'>
                            ICAO:&nbsp;
                            </Typography>
                            <Typography color="textSecondary" variant="subtitle2" display='inline'>
                                {firstLanding.icaoaddress}
                            </Typography>
                            </Box>

                            <Box>
                            <Typography color="textSecondary" display='inline'>
                            Callsign:&nbsp;
                            </Typography>
                            <Typography color="textSecondary" variant="subtitle2" display='inline'>
                                {firstLanding.callsign}
                            </Typography>
                            </Box>

                            {
                            make ?
                            <Box>
                                <Typography color="textSecondary" display='inline'>
                                Make:&nbsp;
                                </Typography>
                                <Typography color="textSecondary" variant="subtitle2" display='inline'>
                                {make}
                                </Typography>
                            </Box>
                            :
                            null
                            }

                            {
                            model ?
                            <Box>
                                <Typography color="textSecondary" display='inline'>
                                Model:&nbsp;
                                </Typography>
                                <Typography color="textSecondary" variant="subtitle2" display='inline'>
                                {model}
                                </Typography>
                            </Box>
                            :
                            null
                            }
                            </Grid>
                    </Grid>
                    <br />
                    <Typography color="textSecondary" variant="subtitle2"  display='inline'>
                        Date:&nbsp;
                    </Typography>
                    <Typography color="textSecondary"  variant="subtitle2" display='inline'>
                        {(new Date(firstLanding.created)).toLocaleDateString([], { year:  'numeric', month: '2-digit', day:   '2-digit' })}&nbsp;&nbsp;
                        {(new Date(firstLanding.created)).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit', hour12: true })}
                    </Typography>
                    &nbsp;&nbsp;

                    
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5} style={{textAlign: "center", paddingTop: '20px'}}>
                    <Weather code={airportCode} ts={firstLanding.created} id={firstLanding.id} setSourceWeatherData={setSourceWeatherData}  /> 
                </Grid>
                <Grid item xs={12} sm={3} style={{textAlign: "right", zoom: 0.9, paddingRight: '25px'}}>
                    <AnalysisNotam id={firstLanding.id} landingrunway={firstLanding.landingrunway} />
                </Grid>
            </Grid> */}
            <Link to={`/landing/${firstLanding.id}`} title={"Click to view full Map"}>
                <MapPlot parentRef={paperRef} runwayName={runwayName} landing={firstLanding.landingdata} alerts={firstLanding.landingalerts} airportCode={airportCode} satimgTopLeft={airport.satimg_topleft.coordinates} polygon={runwayPolygon} midpoint1={midpoint1} midpoint2={midpoint2} />
            </Link>
            {/* <DashboardAirportImage /> */}
            {
                firstLanding.landinganalysis && <GroundSpeed yAxisLabelFontSize='20px' height="250" width_auto={true} root={false} data={JSON.parse(firstLanding.landinganalysis.speedDistanceData)} touchdown={firstLanding.landinganalysis.touchdown} glideslope_touchdown={slope_distance} length={runways[runway]['length_ft']} />
            }

            {
                firstLanding.landinganalysis && <DecelerationVsDistance height="250" width_auto={true} root={false} data={JSON.parse(firstLanding.landinganalysis.decelerationDistanceData)} touchdown={firstLanding.landinganalysis.touchdown} glideslope_touchdown={slope_distance} length={runways[runway]['length_ft']} />
            }

            {
                firstLanding.landinganalysis && <DirectionalCompliance yAxisLabelFontSize='18px' height="250" width_auto={true} root={false} data={JSON.parse(firstLanding.landinganalysis.directionalComplianceSmoothedData)} touchdown={firstLanding.landinganalysis.touchdown} glideslope_touchdown={slope_distance} length={runways[runway]['length_ft']} width={runways[runway]['width_ft']} wind_direction={0} wind_speed={0} source={sourceWeatherData?.source} />
            }
        </Paper>
    </div>
}